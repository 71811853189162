.projects-container {
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
  text-align: left;
}

.projects-container .projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.projects-container .projects-grid .project {
  text-align: left;
}

.project h2:hover {
  text-decoration: underline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.zoom {
  border-radius: 5px;
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.05);
}

.projects-container .projects-grid .project {
  text-align: left;
}

.project h2:hover {
  text-decoration: underline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.zoom {
  border-radius: 5px;
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.05);
}

.project-buttons {
  display: flex;
  gap: 15px;
}

/* Allgemeiner Button-Stil */
.button {
  padding: 2px 8px;
  border: 1px solid black; /* Standard für Light Mode */
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.3s ease;
}

/* Dark Mode spezifisch */
.dark-mode .button {
  border-color: white; /* Randfarbe für Dark Mode */
}

/* Light Mode spezifisch (optional, falls du den Rand in Light Mode auch anpassen willst) */
.light-mode .button {
  border-color: black; /* Randfarbe für Light Mode */
}

.projects-container {
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
  text-align: left;
}

.projects-container .projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

@media screen {
  @media (max-width: 700px) {
    .projects-container {
      display: block;
      padding-left: 5%;
      padding-right: 5%;
    }

    .projects-container .projects-grid {
      display: block;
    }

    .projects-container .projects-grid .project {
      padding-bottom: 5%;
    }
  }
}

@media (max-width: 700px) {
  .projects-container {
    display: block;
    padding-left: 5%;
    padding-right: 5%;
  }

  .projects-container .projects-grid {
    display: block;
  }

  .projects-container .projects-grid .project {
    padding-bottom: 5%;
  }
}
