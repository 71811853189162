.about-container {
  padding: 5% 10%;
  text-align: left;
  color: white;
}

.about-container h1 {
  font-size: 2.5rem;
  font-family: "DomaineDispNar-Medium", sans-serif;
  margin-bottom: 1.5rem;
}

.about-content {
  font-size: 1.2rem;
  line-height: 1.6;
  font-family: "Source Code Pro", monospace;
  color: white;
}

.about-content p {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .about-container {
    padding: 5%;
  }

  .about-container h1 {
    font-size: 2rem;
  }

  .about-content {
    font-size: 1rem;
  }
}
